import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import { createCheckoutSession } from "../API/ServerApi";

interface ShouldPaymentModalProps {
  showPaymentPopup: boolean;
  setShowPaymentPopup: (value: boolean) => void;
  onAction:(value:any)=>void;
}

const ShouldPaymentModal: FC<ShouldPaymentModalProps> = ({
  showPaymentPopup,
  setShowPaymentPopup,
  onAction
}) => {
  const navigate = useNavigate();
  const {t}=useTranslation()


    const handlePaymentClicked = async () => {
        const stripePromise = loadStripe('pk_test_51Q3jdsBxh4wDhCoM4XoEiGBlNg3R4hyAhD50xVs04lzgDwd1SJkSufNCumuohKAYmkQDDrsxz3IBb0wsc96COybC00HpfVIzSx');
        
      const stripe = await stripePromise;
      if (!stripe) {
        console.log('something wrong with Stripe');
        return;
      }
      const response = await createCheckoutSession();
      if (!response || !response?.session) {
        console.log('something wrong with Stripe');
        return;
      }
      const { id } = response?.session;
      const result = await stripe.redirectToCheckout({ sessionId: id });
      if (result.error) {
        alert(result.error.message);
      }
    };


  const handleRedirectToPayment = async() => {

    await handlePaymentClicked().then(()=>{
        setShowPaymentPopup(false);
    })


  };

  const handleRedirectToHome = () => {
    setShowPaymentPopup(false);
    navigate("/");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div
        className="bg-gradient-to-br from-green-100 via-green-200 to-green-300 text-gray-900 p-10 rounded-2xl shadow-2xl flex flex-col items-center gap-6
        xl:w-[700px] xl:h-[400px]
        lg:w-[600px] lg:h-[350px]
        md:w-[500px] md:h-[300px]
        sm:w-[400px] sm:h-auto
        w-[90%] h-auto"
      >
        <h2 className="text-3xl sm:text-4xl font-extrabold text-center text-green-800 mb-4">
          {t('noTokens')}
        </h2>
        <p className="text-base sm:text-lg text-center text-gray-700">
         
          {t('noTokensDesc')}
        </p>
        <div className="flex flex-col sm:flex-row gap-4 w-full justify-center">
          <button
            onClick={handleRedirectToPayment}
            className="w-full sm:w-auto px-6 py-3 bg-green-600 hover:bg-green-700 text-white rounded-lg text-lg font-semibold shadow-md hover:shadow-lg transition-all"
          >
            {t('goPayment')}
          </button>
          <button
            onClick={handleRedirectToHome}
            className="w-full sm:w-auto px-6 py-3 bg-white hover:bg-green-100 text-green-800 rounded-lg text-lg font-semibold shadow-md hover:shadow-lg transition-all"
          >
            {t('goHome')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShouldPaymentModal;
