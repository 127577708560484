import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTopYellowCardsFromServer } from "../../API/ServerApi";

interface PlayerStats {
  player: {
    id: number;
    name: string;
    age: number;
    photo: string;
  };
  team: {
    name: string;
    logo: string;
  };
  statistics: {
    games: {
      appearances?: number;
      minutes?: number;
      position?: string;
      rating?: string;
    };
    cards: {
      yellow?: number;
      red?: number;
    };
    goals: {
      total?: number;
      assists?: number;
    };
    penalty: {
      scored?: number;
      missed?: number;
    };
  };
}

const TopYellowCards = () => {
  const { t } = useTranslation();
  const [topYellowCards, setTopYellowCards] = useState<PlayerStats[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getStats = async () => {
      setIsLoading(true);
      try {
        const response = await getTopYellowCardsFromServer();
        if (response) {
          const formattedResponse = response.map((item: any) => ({
            ...item,
            team: item.statistics[0]?.team || {},
            statistics: item.statistics[0] || {},
          }));
          setTopYellowCards(formattedResponse);
        }
      } catch (error) {
        console.error("Error fetching yellow cards data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getStats();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#1F5059] mb-4">
        {t("topyellowcards")}
      </h1>

      {isLoading ? (
        <div className="text-center text-[#1F5059] font-semibold">{t("loading")}</div>
      ) : (
        <div className="w-full max-w-7xl overflow-x-auto shadow-lg rounded-lg bg-white">
          <table className="table-auto w-full border-collapse">
            <thead>
              <tr className="bg-[#1F5059] text-white text-sm sm:text-base ">
                <th className="px-4 py-2">{t("rank")}</th>
                <th className="px-4 py-2">{t("player")}</th>
                <th className="px-4 py-2">{t("age")}</th>
                <th className="px-4 py-2">{t("team")}</th>
                <th className="px-4 py-2">{t("appearances")}</th>
                <th className="px-4 py-2">{t("yellowcards")}</th>
                <th className="px-4 py-2">{t("redcards")}</th>
                <th className="px-4 py-2">{t("goals")}</th>
                <th className="px-4 py-2">{t("assists")}</th>
                <th className="px-4 py-2">{t("minutes")}</th>
                <th className="px-4 py-2">{t("position")}</th>
                <th className="px-4 py-2">{t("rating")}</th>
                <th className="px-2 py-2">{t("penalty")}</th>
              </tr>
            </thead>
            <tbody >
              {topYellowCards.map((item, index) => (
                <tr
                  key={item.player.id}
                  className={`bg-gray-50 text-center text-xs sm:text-sm md:text-base${index % 2 === 0 ? "bg-white" : "bg-gray-50"
                    }`}
                >
                  <td className="px-2 py-1 text-center">{index + 1}</td>
                  <td className="px-2 py-1 flex items-center">
                    <img
                      src={item.player.photo}
                      alt={item.player.name}
                      className="w-5 h-5 xss:w-6 xss:h-6 sm:w-8 sm:h-8 mr-2"
                    />
                    {item.player.name}
                  </td>
                  <td className="px-2 py-1 text-center">{item.player.age}</td>
                  <td className="px-2 py-1 flex items-center">
                    <img
                      src={item.team.logo}
                      alt={item.team.name}
                      className="w-5 h-5 xss:w-6 xss:h-6 sm:w-8 sm:h-8 mr-2"
                    />
                    {item.team.name}
                  </td>
                  <td className="px-2 py-1 text-center">
                    {item.statistics.games.appearances || 0}
                  </td>
                  <td className="px-2 py-1 text-center">
                    {item.statistics.cards.yellow || 0}
                  </td>
                  <td className="px-2 py-1 text-center">
                    {item.statistics.cards.red || 0}
                  </td>
                  <td className="px-2 py-1 text-center">
                    {item.statistics.goals.total || 0}
                  </td>
                  <td className="px-2 py-1 text-center">
                    {item.statistics.goals.assists || 0}
                  </td>
                  <td className="px-2 py-1 text-center">
                    {item.statistics.games.minutes || 0}
                  </td>
                  <td className="px-2 py-1 text-center">
                    {item.statistics.games.position || "-"}
                  </td>
                  <td className="px-2 py-1 text-center">
                    {parseFloat(item.statistics.games.rating || "0").toFixed(2)}
                  </td>
                  <td className="px-2 py-1 text-center">
                    {`${item.statistics.penalty.scored ?? 0}/${(item.statistics.penalty.scored ?? 0) + (item.statistics.penalty.missed ?? 0)
                      }`}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TopYellowCards;
