import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Define your translations as types for stronger type checking
const resources = {
  English: {
    translation: {
      welcome: "Welcome",
      home: "Home",
      about: "About",
      contact: "Contact",
      login: "Login",
      signUp: "Sign Up",
      logout: "Logout",
      payment: "Payment",
      tokens: "tokens",
      english: "English",
      arabic: "العربية",
      games: "Games",
      game: "Game",
      news: "News",
      table: "Table",
      stats: "Stats",
      gossip: "Gossip",
      welcomeMessage: "Welcome to Saudi Football weekly, friends and supporters of Saudi Arabian Football. We aim to raise awareness and interest in Saudi Football. For that, we provide you with all that happens in Saudi Arabia in and around Football, inform, educate, entertain, and hopefully fascinate you as much as we are.",
      predictionPage: "Take me to the prediction page",
      topScorer: "Top scorer to",
      finalMatch: "the final match",
      recentNews: "Recent News",
      clubsRanking: "Clubs Ranking",
      studyConfirmation: "ARE YOU SURE THAT YOU HAVE STUDIED ENOUGH?",
      donePlenty: "YES, I HAVE DONE PLENTY! TAKE ME TO THE PREDICTION PAGE",
      takeMeBack: "ACTUALLY, YOU ARE RIGHT. TAKE ME BACK TO THE INFORMATION SECTION",
      showMore: "Show More",
      saudiLeagueGames: "Saudi league Games",
      matchHome: "Home",
      matchVs: "vs",
      matchAway: "Away",
      matchDate: "Match Date",
      rank: "Rank",
      team: "Team",
      points: "Pts",
      gamesPlayed: "GP",
      wins: "W",
      draws: "D",
      losses: "L",
      goalsFor: "F",
      goalsAgainst: "A",
      goalDifference: "GD",
      topScorers: "Top Scorers",
      topAssissts: "Top Assists",
      yellowcards: "Yellow Cards",
      redcards: "Red Cards",
      topyellowcards: "Top Yellow Cards",
      topredcards: "Top Red Cards",
      player: "Player",
      age: "Age",
      appearances: "Appearances",
      goals: "Goals",
      assists: "Assists",
      minutes: "Minutes",
      position: "Position",
      rating: "Rating",
      penalty: "Penalty",
      shotsOn: "Shots On",
      dribblesWon: "Dribbles Won",
      mostViews: "Most Views",
      source: "Source",
      publishedAt: "Published At",
      pool1: "POOL 1",
      pool2: "POOL 2",
      pool3: "POOL 3",

      "Percentages": {
        "min": "Minimum percentages",
        "accumulated": "Accumulated percentages",
        "max": "Maximum percentages"
      },
      "gamesRules": {
        "title": "Games Rules & How to Play",
        "download": "Download Rules",
        "welcomeMessage": "Hello my friend and a warm welcome to <strong>Saudifootballweekly.com</strong>, the only information site on Saudi football you will ever need.",
        "infoMessage1": "We will inform you about everything that goes on in Saudi football, give you news, statistics, insights, outlooks, and evaluations.",
        "infoMessage2": "But it doesn't stop there! We want to turn you into an expert, a true professional when it comes to Saudi football. So we invented a challenge to test your skills: <strong>‘The Prediction Challenge’</strong>.",
        "whatIsChallenge": "What is the Prediction Challenge?",
        "challengeDescription": "It is completely free of charge as it comes as a bonus with your monthly subscription. The challenge is to predict the winner in <strong>7 of the weekly games</strong> of the Saudi Pro League and the exact result in the other <strong>2 games</strong>.",
        "howItWorks": "How Does It Work?",
        "howItWorksList": {
          "point1": "You receive <strong>8 Tokens</strong> every month with your subscription.",
          "point2": "You can use <strong>2 Tokens per week</strong> to participate in the Prediction Challenge.",
          "point3": "All Tokens will be redistributed to participants who predicted the right results through three reward pools."
        },
        "rewardPools": "Reward Pools",
        "tableHeaders": {
          "pool": "Pool",
          "requirement": "Requirement",
          "reward": "Reward"
        },
        "tableContent": {
          "pool1": {
            "name": "Pool 1",
            "requirement": "7 Games Correct",
            "reward": "Tokens equally shared among participants."
          },
          "pool2": {
            "name": "Pool 2",
            "requirement": "7 Games Correct + 1 Exact Score",
            "reward": "Share of Pool 1 + Pool 2."
          },
          "pool3": {
            "name": "Pool 3",
            "requirement": "7 Games Correct + 2 Exact Scores",
            "reward": "Share of Pool 1 + Pool 2 + Pool 3."
          }
        },
        "jackpot": "If no one gets the results right, we create a jackpot for the respective pool that will roll over to the next week. Have fun while you improve your knowledge of Saudi football!",
       
      },
      vs: "vs",
      gamesRight: "7 games right",
      gamesRightone: "games right + 1 exact score",
      MyPrediction: "My Prediction",
      rulesTitle: "Rules",
      Pools: "Pools",
      Createdat: "Created at",
      gamesRighttwo: "games + 2 exact scores",
      jackpot: "JACKPOT: Rial XXXX",
      NoPredictionsYet: "You have no predictions yet!",
      predictions: "predictions",
      MatchHistory: "Match history",
      somethingWrong: "Something went wrong , please contact us!",
      mypredictions: "My Predictions",
      oursponsors: "Our Sponsors",
      "ThisWeekExtra": {
        "header1": "This week's extra",
        "header2": "This week's extra",
        "header3": "This week's extra",
        "sponsor": "(Sponsor)"
      },
      "signupTitle": "Sign Up",
      "labelName": "Full Name",
      "labelGender": "Gender",
      "labelDOB": "Date of Birth",
      "labelAddress": "Address",
      "labelEmail": "Email",
      "labelPassword": "Password",
      "genderMale": "Male",
      "genderFemale": "Female",
      "genderOther": "Other",
      "placeholderName": "Your Name",
      "placeholderAddress": "1234 Main St",
      "placeholderEmail": "you@example.com",
      "placeholderPassword": "******",
      "buttonSignup": "Sign Up",
      "errorFillAllFields": "Please fill in all required fields.",
      "errorShortPassword": "Password must be at least 6 characters!",
      "errorTryAgain": "Something went wrong, please try again!",
      "loginTitle": "Login",
      "buttonSignIn": "Sign in",
      "forgotPassword": "Forgot your password?",
      "errorFillFields": "Please fill in all fields.",
      "errorLoginFailed": "Login failed. Please check your email and password.",
      "labelConfirmPassword": "Confirm Password",
      "placeholderConfirmPassword": "Re-enter your password",
      "errorPasswordMismatch": "Passwords do not match",
      "acceptTerms": "I accept the",
      "gamerules": "Game rules",
      "termsConditions": "Terms and Conditions",
      "errorAcceptTerms": "You must accept the Terms and Conditions to proceed",
      "errorAcceptRules": "You must accept the Game rules to proceed",
      "labelOtp": "Enter OTP",
      "placeholderOtp": "Enter the OTP sent to your email",
      "errorOtpRequired": "OTP is required",
      "errorOtpInvalid": "Invalid OTP",
      "buttonVerifyOtp": "Verify OTP",
      'Rules': "Rules",
      "generalInfos": "General infos",
      "teamsInfo": "Teams info",
      founded: "founded",
      "Round": "Round",
      theRound: "Round",
      accept: "Accept",
      MyResults: "My Results",
      Submit: "Submit",
      noTokens: "IMPORTANT INFO",
      noTokensDesc: "To view all the information on our site you need to subscribe.",
      goPayment: "Subscribe now",
      goHome: "Go to Home page",
       "roundStarted": "Round Started",
        "predictionsNotAllowed": "Predictions are no longer allowed for this round. See you at the next round!"
      // Add other English translations here
    },
  },
  Arabic: {
    translation: {
      welcome: "مرحبا",
      ThisWeekExtra: {
        "header1": "إضافات هذا الأسبوع",
        "header2": "إضافات هذا الأسبوع",
        "header3": "إضافات هذا الأسبوع",
        "sponsor": "(الراعي)"
      },
      "Percentages": {
        "min": "أقل النسب المئوية",
        "accumulated": "النسب المئوية المتراكمة",
        "max": "أعلى النسب المئوية"
      },
      "gamesRules": {
        "title": "قواعد اللعبة وكيفية اللعب",
        "download": "تحميل القواعد",
        "welcomeMessage": "مرحباً صديقي، ونرحب بك بحرارة في موقع <strong>Saudifootballweekly.com</strong>، الموقع المعلوماتي الوحيد عن كرة القدم السعودية الذي ستحتاجه على الإطلاق.",
        "infoMessage1": "سنطلعك على كل ما يحدث في كرة القدم السعودية، ونقدم لك الأخبار والإحصاءات والتحليلات والتوقعات والتقييمات.",
        "infoMessage2": "ولكن الأمر لا يتوقف عند هذا الحد! نحن نريد أن نحولك إلى خبير حقيقي، محترف عندما يتعلق الأمر بكرة القدم السعودية. لذلك ابتكرنا تحدياً لاختبار مهاراتك، نطلق عليه اسم <strong>\"تحدي التوقعات\"</strong>.",
        "whatIsChallenge": "ما هو تحدي التوقعات؟",
        "challengeDescription": "هذا التحدي مجاني تماماً، حيث يأتي كميزة إضافية مع اشتراكك الشهري. يقوم التحدي على توقع الفائز في <strong>7 مباريات</strong> من الدوري السعودي للمحترفين في الأسبوع، بالإضافة إلى التنبؤ بالنتيجة الدقيقة في <strong>مباراتين أخريين</strong>.",
        "howItWorks": "كيف يعمل؟",
        "howItWorksList": {
          "point1": "ستحصل على <strong>8 \"توكنز\"</strong> (Tokens) كل شهر مع اشتراكك.",
          "point2": "يمكنك استخدام <strong>2 توكن</strong> كل أسبوع للمشاركة في \"تحدي التوقعات\".",
          "point3": "سيتم إعادة توزيع جميع التوكنز المجمّعة على المشاركين الذين نجحوا في توقع النتائج الصحيحة."
        },
        "rewardPools": "صناديق المكافآت",
        "tableHeaders": {
          "pool": "الصندوق",
          "requirement": "المتطلب",
          "reward": "المكافأة"
        },
        "tableContent": {
          "pool1": {
            "name": "المجموعة 1",
            "requirement": "7 مباريات صحيحة",
            "reward": "سيتم تقاسم جميع التوكنز بالتساوي بين المشاركين الذين توقعوا النتائج بشكل صحيح."
          },
          "pool2": {
            "name": "المجموعة 2",
            "requirement": "7 مباريات صحيحة + نتيجة دقيقة واحدة",
            "reward": "تتضمن نصيبك من المجموعة 1 أيضاً."
          },
          "pool3": {
            "name": "المجموعة 3",
            "requirement": "7 مباريات صحيحة + نتيجتان دقيقتان",
            "reward": "تتضمن نصيبك من المجموعة 2 والمجموعة 1."
          }
        },
        "jackpot": "سيتم تقاسم جميع التوكنز في كل مجموعة بالتساوي بين جميع المشاركين الذين توقعوا النتائج بشكل صحيح. على سبيل المثال، إذا كانت هناك 1000 توكن في المجموعة 1، ونجح 20 مشاركاً في التوقع الصحيح، فسيحصل كل منهم على (1000 ÷ 20 = 50) توكن تُضاف إلى محفظته. ويمكن استخدام التوكنز التي تربحها في الاسترداد أو للمشاركة في تحدي التوقعات الأسبوعي القادم. إذا توقعت النتائج الصحيحة التي تؤهلك للفوز في المجموعة 2، فستحصل تلقائياً على نصيبك من المجموعة 1 أيضاً. وبالنسبة للفائزين في المجموعة 3، فسيحصلون كذلك على نصيب من المجموعة 2 والمجموعة 1. </br> في حال لم ينجح أي شخص في توقع النتائج الصحيحة، سننشئ <strong>\"جائزة كبرى\" (Jackpot)</strong> للمجموعة المعنية، تنتقل قيمتها للأسبوع التالي. </br> استمتع بينما تطوّر معرفتك بكرة القدم السعودية!",
        "enjoy": "استمتع بينما تطوّر معرفتك بكرة القدم السعودية!"
      },
      home: "الرئيسية",
      topAssissts: "أفضل التمريرات الحاسمة",
      Createdat: "تم إنشاؤها في",
      predictions: "توقعات ",
      MyPrediction: "توقعاتي",
      MyResults: "نتائجي",
      MatchHistory: "المباراة",
      Pools: "المجموعة",
      pool1: "1 المجموعة",
      pool2: "2 المجموعة",
      pool3: "3 المجموعة",
      about: "حول",
      contact: "اتصال",
      login: "تسجيل الدخول",
      signUp: "إنشاء حساب",
      logout: "تسجيل الخروج",
      payment: "الدفع",
      tokens: "رموز",
      english: "English",
      arabic: "العربية",
      games: "المباريات",
      game: "مباراة",
      news: "أخبار",
      table: "الجدول",
      stats: "إحصائيات",
      gossip: "أخبار الانتقالات",
      welcomeMessage: "مرحبًا بكم في أسبوع كرة القدم السعودية، أصدقاء ومشجعو كرة القدم السعودية. نهدف إلى زيادة الوعي والاهتمام بكرة القدم السعودية. لذلك، نقدم لكم كل ما يحدث في السعودية في كرة القدم وحولها، نعلمكم، نثقفكم، نرفه عنكم، ونأمل أن نثير إعجابكم كما نفعل نحن.",
      predictionPage: "خذني إلى صفحة التوقعات",
      topScorer: "أفضل هداف إلى",
      finalMatch: "المباراة النهائية", // Entry for the second part in Arabic
      recentNews: "الأخبار الأخيرة",  // New entry for Recent News in Arabic
      clubsRanking: "ترتيب الأندية", // New entry for Clubs Ranking in Arabic
      studyConfirmation: "هل أنت متأكد أنك درست بما فيه الكفاية؟", // New entry for study confirmation in Arabic
      donePlenty: "نعم، لقد قمت بذلك كثيرًا! خذني إلى صفحة التوقعات", // Button text for prediction page in Arabic
      takeMeBack: "في الواقع، أنت محق. خذني إلى قسم المعلومات", // Button text for information section in Arabic
      showMore: "عرض المزيد", // New entry for Show More in Arabic
      saudiLeagueGames: "مباريات الدوري السعودي", // New entry for Saudi league Games in Arabic
      matchHome: "الديار", // New entry for Home in Arabic
      matchVs: "ضد",       // New entry for vs in Arabic
      matchAway: "الخارج", // New entry for Away in Arabic
      matchDate: "تاريخ المباراة", // New entry for Match Date in Arabic
      rank: "الترتيب",           // New entry for Rank in Arabic
      team: "الفريق",            // New entry for Team in Arabic
      points: "النقاط",          // New entry for Points in Arabic
      gamesPlayed: "المباريات",  // New entry for Games Played in Arabic
      wins: "الانتصارات",        // New entry for Wins in Arabic
      draws: "التعادل",          // New entry for Draws in Arabic
      losses: "الخسائر",        // New entry for Losses in Arabic
      goalsFor: "الأهداف المسجلة", // New entry for Goals For in Arabic
      goalsAgainst: "الأهداف المستقبلة", // New entry for Goals Against in Arabic
      goalDifference: "فرق الأهداف", // New entry for Goal Difference in Arabic
      topScorers: "أفضل الهدافين", // New entry for Top Scorers in Arabic

      player: "اللاعب",                // New entry for Player in Arabic
      age: "العمر",                    // New entry for Age in Arabic
      appearances: "المباريات",        // New entry for Appearances in Arabic
      goals: "الأهداف",                // New entry for Goals in Arabic
      assists: "التمريرات الحاسمة",    // New entry for Assists in Arabic
      minutes: "الدقائق",              // New entry for Minutes in Arabic
      position: "المركز",              // New entry for Position in Arabic
      rating: "التقييم",                // New entry for Rating in Arabic
      penalty: "ركلة جزاء",            // New entry for Penalty in Arabic
      shotsOn: "التسديدات على المرمى", // New entry for Shots On in Arabic
      dribblesWon: "المراوغات الناجحة", // New entry for Dribbles Won in Arabic
      mostViews: "الأكثر مشاهدة", // New entry for Most Views in Arabic
      source: "المصدر",              // New entry for Source in Arabic
      publishedAt: "تاريخ النشر",     // New entry for Published At in Arabic
      // New entry for POOL 3 in Arabic
      gamesRight: "مباريات صحيحة 7",      // New entry for games right in Arabic
      gamesRightone: "مباريات صحيحة + نتيجة واحدة دقيقة",
      gamesRighttwo: "مباريات + نتيجتان دقيقتان",
      vs: "ضد",
      rulesTitle: "القواعد",
      accept: "موافق",
      jackpot: "الجائزة الكبرى: ريال XXXX", // New entry for JACKPOT in Arabic
      NoPredictionsYet: "ليس لديك أي توقعات بعد!",
      somethingWrong: "حدث خطأ، يرجى الاتصال بنا!",
      mypredictions: "توقعاتي",
      oursponsors: "رعاتنا",
      "signupTitle": "التسجيل",
      "labelName": "الاسم الكامل",
      "labelGender": "الجنس",
      "labelDOB": "تاريخ الميلاد",
      "labelAddress": "العنوان",
      "labelEmail": "البريد الإلكتروني",
      "labelPassword": "كلمة المرور",
      "genderMale": "ذكر",
      "genderFemale": "أنثى",
      "genderOther": "آخر",
      "placeholderName": "اسمك",
      "placeholderAddress": "1234 الشارع الرئيسي",
      "placeholderEmail": "you@example.com",
      "placeholderPassword": "******",
      "buttonSignup": "سجل",
      "errorFillAllFields": "يرجى ملء جميع الحقول المطلوبة.",
      "errorShortPassword": "يجب أن تكون كلمة المرور 6 أحرف على الأقل!",
      "errorTryAgain": "حدث خطأ ما، يرجى المحاولة مرة أخرى!",
      "loginTitle": "تسجيل الدخول",
      "buttonSignIn": "تسجيل الدخول",
      "forgotPassword": "هل نسيت كلمة المرور؟",
      "errorFillFields": "يرجى ملء جميع الحقول.",
      "errorLoginFailed": "فشل تسجيل الدخول. يرجى التحقق من البريد الإلكتروني وكلمة المرور.",
      "labelConfirmPassword": "تأكيد كلمة المرور",
      "placeholderConfirmPassword": "أعد إدخال كلمة المرور",
      "errorPasswordMismatch": "كلمتا المرور غير متطابقتين",
      "acceptTerms": "أوافق على",
      "gamerules": "قواعد اللعبة",
      "termsConditions": "الشروط والأحكام",
      "errorAcceptTerms": "يجب الموافقة على الشروط والأحكام للمتابعة",
      "errorAcceptRules": "يجب الموافقة على قواعد اللعبة للمتابعة",
      "labelOtp": "أدخل رمز التحقق",
      "placeholderOtp": "أدخل رمز التحقق المرسل إلى بريدك الإلكتروني",
      "errorOtpRequired": "رمز التحقق مطلوب",
      "errorOtpInvalid": "رمز التحقق غير صحيح",
      "buttonVerifyOtp": "تحقق من الرمز",
      "generalInfos": "معلومات عامة",
      "teamsInfo": "معلومات عن الفرق",
      yellowcards: "البطاقات الصفراء",
      redcards: "البطاقات الحمراء",
      topyellowcards: "أكثر البطاقات الصفراء",
      topredcards: "أكثر البطاقات الحمراء",
      founded: "تأسست",
      "Round": "جولة",
      theRound: "الجولة",
      Submit: "المتابعة",
      "noTokens": "معلومات مهمة",
      "noTokensDesc": "للاطلاع على جميع المعلومات على موقعنا، تحتاج إلى الاشتراك.",
      "goPayment": "اشترك الآن",
      "goHome": "اذهب إلى الصفحة الرئيسية",
      "roundStarted": "الجولة قد بدأت",
      "predictionsNotAllowed": "لا يمكن تقديم التوقعات لهذه الجولة."

      // Add other Arabic translations here
    },
  },
} as const; // using `as const` to make it a read-only type

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('selectedLanguage') || 'English', // Default language or stored preference
    interpolation: { escapeValue: false }, // React already does XSS prevention
  });

export default i18n;
