import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getTeamsFromServer } from "../../API/ServerApi";


const GeneralInfos = () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language == "Arabic";

  const navigate = useNavigate()
  const [teams, setTeams] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [arabicTeams, setArabicTeams] = useState<any[]>([])
  const [englishTeams, setEnglishTeams] = useState<any[]>([])

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const onclickTeam = () => {
    navigate("/stats")
    window.scrollTo(0, 0);
  }
  const getSATeams = async () => {
    setLoading(true);
    try {
      const response = await getTeamsFromServer();
      if (response && response?.length) {

        const arabicTeams: any[] = response.map((team: any) => team.ar)
        const englishTeams: any[] = response.map((team: any) => team.en)

        setArabicTeams(arabicTeams)
        setEnglishTeams(englishTeams)

        if (i18n.language == 'Arabic') {
          setTeams(arabicTeams);
        } else {
          setTeams(englishTeams);
        }

      }
    } catch (error) {
      console.error("Failed to fetch teams:", error);
    } finally {
      setLoading(false);
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      const scrollDistance = window.innerWidth > 768 ? 300 : 265; // Adjust for larger/smaller screens
      scrollContainerRef.current.scrollBy({
        left: -scrollDistance,
        behavior: "smooth",
      });
    }
  };
  
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      const scrollDistance = window.innerWidth > 768 ? 300 : 265; // Adjust for larger/smaller screens
      scrollContainerRef.current.scrollBy({
        left: scrollDistance,
        behavior: "smooth",
      });
    }
  };
  

  useEffect(() => {
    getSATeams();
  }, []);


  useEffect(() => {
    if (i18n.language == 'Arabic') {
      setTeams(arabicTeams);
    } else {
      setTeams(englishTeams);
    }
  }, [i18n.language])

  return (
    <div className="w-full px-4 sm:px-6 lg:px-8 pt-10 " dir={isArabic ? "rtl" : "ltr"}>
      <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-6 sm:mb-8">
        {t("generalInfos")}
      </h1>
      {loading ? (
        <p className="text-center text-md sm:text-lg">{t("loading")}...</p>
      ) : (
        <div className="relative">
          {/* Scrollable Container */}
          <div
            ref={scrollContainerRef}
            className="flex gap-4 sm:gap-6 overflow-x-auto scrollbar-hide scroll-smooth">
            {teams.map((item) => (
              <div
                onClick={onclickTeam}
                key={item.team.id}
                className="bg-gradient-to-r from-green-200 to-teal-400 shadow-lg rounded-lg p-4 flex-shrink-0 w-[250px] sm:w-[300px] transform hover:scale-105 transition-transform duration-300"
              >
                <div className="flex flex-col items-center text-center">
                  <img
                    src={item.team.logo}
                    alt={item.team.name}
                    className="w-16 h-16 sm:w-20 sm:h-20 mb-4 rounded-full border-2 border-white"
                  />
                  <h2 className="text-base sm:text-lg font-semibold text-gray-800">
                    {item.team.name}
                  </h2>
                  <p className="text-xs sm:text-sm text-gray-700">{item.team.country}</p>
                  <p className="text-xs text-gray-600 mt-1">
                    {t("founded")}: {item.team.founded}
                  </p>
                </div>
                <div className="mt-4 bg-white p-2 sm:p-3 rounded-lg shadow-md flex items-center">
                  <img
                    src={item.venue.image}
                    alt={item.venue.name}
                    className="w-16 h-16 sm:w-24 sm:h-24 mr-4 rounded-lg object-cover"
                  />
                  <div className="text-left text-xs sm:text-sm">
                    <p className="font-semibold text-gray-800">{item.venue.name}</p>
                    <p className="text-gray-600">{item.venue.city}</p>
                    <p className="text-gray-600">{item.venue.capacity} {t("capacity")}</p>
                    <p className="text-gray-600">{t("surface")}: {item.venue.surface}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Scroll Buttons */}
          <button
            onClick={scrollLeft}
            className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 sm:p-3 rounded-full shadow-lg hover:bg-gray-600 transition-colors"
          >
            <FaChevronCircleLeft />
          </button>
          <button
            onClick={scrollRight}
            className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 sm:p-3 rounded-full shadow-lg hover:bg-gray-600 transition-colors"
          >
            <FaChevronCircleRight />
          </button>
        </div>
      )}
    </div>

  );
};

export default GeneralInfos;
