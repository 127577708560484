import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Assuming you're using react-router-dom for routing
import Navbar from "./components/Navbar";
import { useAuth } from "./context/AuthContext";
import ShouldPaymentModal from "./components/ShouldPaymentModal";

interface LayoutProps {
  children: ReactNode;
  paddingTop?: string; // Optional padding-top prop
  user?: { tokens: number }; // User object with tokens property
}

const Layout = ({ children, paddingTop = "pt-40" }: LayoutProps) => {
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const {  user,userBalance } = useAuth();


  useEffect(() => {
    const allowedPaths=["/gameRules","/","/update-payment","/login/","/signup"]
    const balance=userBalance as any
    console.log('location.pathname',allowedPaths.includes(location.pathname),userBalance,balance===0)
    if (user && balance===0 &&  !allowedPaths.includes(location.pathname) ) { 
     

      // !showPaymentPopup && navigate(-1); 

      setShowPaymentPopup(true);
    }
  }, [userBalance, location, navigate, showPaymentPopup]);



  const handleAction = (action: "payment" | "home") => {
    setShowPaymentPopup(false);
    if (action === "payment") {
      navigate("/update-payment"); // Redirect to payment page
    } else if (action === "home") {
      navigate("/"); // Redirect to home page
    }
  };


  return (
    <div className="flex min-h-screen w-full overflow-hidden bg-[#DDEFE3] flex-col items-center ">
      <div className="w-screen">
      <Navbar />
      </div>
     
      <main
        className="flex flex-grow max-w-[2000px] mx-6 px-0 "
        style={{ position: "relative" }}
      >
        {/* Dynamically apply padding-top */}
        <div className={`${paddingTop} w-full`}>
          {children}
        </div>
      </main>

      {/* Popup for insufficient tokens */}
      {showPaymentPopup && (
       <ShouldPaymentModal  showPaymentPopup={showPaymentPopup} setShowPaymentPopup={setShowPaymentPopup}  onAction={handleAction} />
      )}
    </div>
  );
};

export default Layout;
