import { useState } from "react";
import palmier from "../assets/palmier.svg";
import ads from "../assets/ads.svg";
import player from "../assets/player.svg";
import StandingsTable from "../components/Standings";
import NewsList from "../components/NewsList";
import tape from "../assets/tape.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GeneralInfos from "../components/HomeComponents/GeneralInfos";
import PredictionPopup from "../components/predictionPopup";


function Home() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "Arabic";
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleNavigateToPrediction = () => {
    navigate("/prediction");
  };

  return (

    <section className="w-screen flex flex-col items-center content-center p-4 ">
      <img
        src={palmier}
        alt="palmier"
        className="absolute top-4 left-0 h-screen w-auto"
      />
      <div className="flex flex-col justify-center items-center w-full min-h-screen gap-y-16 md:gap-y-24 px-4 max-w-screen">
        {/* Main Content */}
        <div className="flex flex-col lg:flex-row justify-center items-center w-full gap-y-8 px-4 lg:px-8 pt-16 lg:pt-32">
          <div className="relative p-4 md:p-8 lg:p-16 w-full lg:w-1/2">
            <h1 className="text-2xl sm:text-4xl md:text-[64px] lg:text-[96px] font-bold uppercase leading-7 sm:leading-[80px] text-[#1F5059]">
              {t("topScorer")}{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-b from-[#1F5059] to-[#7ebfac]">
                {t("finalMatch")}
              </span>
            </h1>
            <img
              src={player}
              alt="player"
              className="absolute w-24 sm:w-32 md:w-48 lg:w-[400px] right-4 top-0"
            />
            <img
              src={tape}
              alt="tape"
              className="absolute w-24 sm:w-32 md:w-48 lg:w-[400px] right-4 bottom-0"
            />
          </div>
          <div className="flex flex-col justify-center items-center w-full sm:w-[300px] gap-y-4">
            <p className="text-base sm:text-lg md:text-xl text-center">
              {t("welcomeMessage")}
            </p>
            <button
  className="p-3 rounded-lg text-xl bg-[#056A4C] text-white w-full pointer-events-auto relative"
  onClick={() => {
    setIsPopupOpen(true);
    console.log("popup");
  }}
>
  {t('predictionPage')}
</button>
          </div>
        </div>

        {/* General Info Section */}
        <div className="w-screen px-4">
          <GeneralInfos />
        </div>

        {/* News and Standings */}
        <div className="flex flex-wrap lg:flex-nowrap justify-center items-stretch w-full gap-8 px-4 lg:px-8">
          <div className="flex flex-col w-full lg:w-1/2 bg-white rounded-lg p-4 gap-y-6 items-center">
            <h1 className="text-2xl md:text-4xl font-bold text-gray-800 text-center">
              {t("recentNews")}
            </h1>
            <NewsList />
          </div>
          <div
            className="flex flex-col w-full lg:w-1/2 gap-y-6 items-center lg:items-start"
            dir={isArabic ? "rtl" : "ltr"}
          >
            <h1 className="text-2xl md:text-4xl font-bold text-gray-800">
              {t("clubsRanking")}
            </h1>
            <StandingsTable />
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <PredictionPopup handleClosePopup={() => setIsPopupOpen(false)} />
      )}
    </section>

  );
}

export default Home;
