import { FC, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Layout from "../Layout";
import Home from "../pages/Home";
import LoginPage from "../pages/Login";
import SignupPage from "../pages/Signup";
import GamesRules from "../pages/GameRules";
import ProtectedRoute from "../components/ProtectedRoute";
import UpdatePayment from "../pages/UpdatePayment";
import News from "../pages/News";
import Games from "../pages/Games";
import Stats from "../pages/Stats";
import TableScreen from "../pages/TableScreen";
import LayoutPrediction from "../LayoutPrediction";
import PredictionScreen from "../pages/PredictionScreen";
import MyPredictionsScreen from "../pages/MyPredictions";


const MyRouter:FC=()=>{

    return (


          <Routes>
        {/* Public routes */}
        <Route path="/" element={<Layout><Home /></Layout>} />
        <Route path="/login" element={<Layout><LoginPage /></Layout>} />
        <Route path="/signup" element={<Layout><SignupPage /></Layout>} />
        <Route path="/gameRules" element={<Layout><GamesRules /></Layout>} />


        

        {/* Protected routes */}
        <Route path="/update-payment" element={
          <ProtectedRoute>
            <Layout><UpdatePayment /></Layout>
          </ProtectedRoute>
        } />
        <Route path="/news" element={
          <ProtectedRoute>
            <Layout><News /></Layout>
          </ProtectedRoute>
        } />
        <Route path="/games" element={
          <ProtectedRoute>
            <Layout><Games /></Layout>
          </ProtectedRoute>
        } />
        <Route path="/stats" element={
          <ProtectedRoute>
            <Layout><Stats /></Layout>
          </ProtectedRoute>
        } />
        <Route path="/table" element={
          <ProtectedRoute>
            <Layout><TableScreen /></Layout>
          </ProtectedRoute>
        } />
        <Route path="/prediction" element={
          <ProtectedRoute>
            <LayoutPrediction><PredictionScreen /></LayoutPrediction>
          </ProtectedRoute>
        } />
        <Route path="/mypredictions" element={
          <ProtectedRoute>
            <LayoutPrediction><MyPredictionsScreen /></LayoutPrediction>
          </ProtectedRoute>
        } />
      </Routes>
      
    )

}

export default MyRouter