import "./index.css";
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import MyRouter from "./Router/Router";

const App = () => {
  return (
    <AuthProvider>
     <MyRouter />
    </AuthProvider>
  );
};

export default App;
