import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PoolsPrediction = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [visibleRows, setVisibleRows] = useState(5);

  const showMore = () => {
    navigate("/table");
  };

  // Check if the current language is Arabic for RTL layout
  const isArabic = i18n.language === "Arabic";

  return (
    <div className="flex flex-col items-center px-4 ">
      {/* Table Container */}
      <div className="border-[#4a917c] border rounded-lg w-full mx-2 overflow-x-auto">
        <div >
          <table
            className="table-auto w-full "
            dir={isArabic ? "rtl" : "ltr"}
          >
            {/* Table Header */}
            <thead>
              <tr className="bg-[#97cfaacd] sm:text-xl text-[#358168]">
                <th className="px-4 py-6 text-center sm:px-14">{t("pool1")}</th>
                <th className="px-4 py-6 text-center sm:px-14 ">{t("pool2")}</th>
                <th className="px-4 py-6 text-center sm:px-14">{t("pool3")}</th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody>
              <tr className="text-base font-semibold bg-[#97cfaacd]">
                <td className="px-4 py-6 text-center sm:px-14">{t("gamesRight")}</td>
                <td className="px-4 py-6 text-center sm:px-14">
                  {t("7")} {t("gamesRightone")}
                </td>
                <td className="px-4 py-6 text-center sm:px-14">
                  {t("7")} {t("gamesRighttwo")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* Jackpot Section */}
      <div className="flex justify-center items-center mt-4">
        <h1 className="text-xl font-bold text-[#1f5059e2]">{t("jackpot")}</h1>
      </div>

      {/* Show More Section */}
      {/* <div className="flex justify-center mt-4">
        <button
          onClick={showMore}
          className="px-4 py-2 bg-[#358168] text-white rounded-lg hover:bg-[#2a6e55] transition duration-200"
        >
          {t("showMore")}
        </button>
      </div> */}
    </div>


  );
};

export default PoolsPrediction;
