import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Team {
  id: number;
  name: string;
  logo: string;
}

interface Standing {
  rank: number;
  team: Team;
  points: number;
  goalsDiff: number;
  all: {
    played: number;
    win: number;
    draw: number;
    lose: number;
    goals: {
      for: number;
      against: number;
    };
  };
}

const ThisWeekExtra = () => {
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const { t, i18n } = useTranslation();
  const [visibleRows, setVisibleRows] = useState(5); // Initial visible rows

  const showMore = () => {
    navigate("/table");
  };
  const isArabic = i18n.language === "Arabic";

  return (
    <div className="container py-5 flex flex-col items-center px-4">
    <table
      className="table-auto border-collapse text-left w-full max-w-4xl"
      dir={isArabic ? "rtl" : "ltr"}
    >
      <thead>
        <tr className="bg-white text-xl font-semibold text-[#1F5059]">
          <th className="px-6 py-2">{t('ThisWeekExtra.header1')}</th>
          <th className="px-6 py-2">{t('ThisWeekExtra.header2')}</th>
          <th className="px-6 py-2">{t('ThisWeekExtra.header3')}</th>
        </tr>
      </thead>
      <tbody>
        <tr className="border-b text-xs bg-white">
          <td className="px-6 py-2">{t('ThisWeekExtra.sponsor')}</td>
          <td className="px-6 py-2">{t('ThisWeekExtra.sponsor')}</td>
          <td className="px-6 py-2">{t('ThisWeekExtra.sponsor')}</td>
        </tr>
      </tbody>
    </table>
  </div>
  
  );
};

export default ThisWeekExtra;
