import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTopRedCardsFromServer } from "../../API/ServerApi";

// Define the types
interface Statistics {
  games: {
    appearances: number;
    minutes: number;
    position: string;
    rating: string;
  };
  goals: {
    total: number;
    assists: number | null;
  };
  penalty: {
    scored: number;
    missed: number;
  };
  cards: {
    red: number;
    yellow: number;
  };
}

interface Team {
  logo: string;
  name: string;
}

interface Player {
  id: number;
  name: string;
  photo: string;
  age: number;
}

interface TopRedCard {
  player: Player;
  team: Team;
  statistics: Statistics;
}

const TopRedCards = () => {
  const { t } = useTranslation();
  const [topRedCards, setTopRedCards] = useState<TopRedCard[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getStats = async () => {
      setIsLoading(true);
      try {
        const response: any[] = await getTopRedCardsFromServer();
        if (response) {
          const formattedResponse = response.map((item: any): TopRedCard => ({
            ...item,
            team: item.statistics[0].team,
            statistics: item.statistics[0],
          }));
          setTopRedCards(formattedResponse);
        }
      } catch (error) {
        console.error("Error fetching red cards data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getStats();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      {/* Title */}
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#1F5059] mb-6">
        {t("topredcards")}
      </h1>

      {/* Responsive Table Container */}
      <div className="w-full max-w-7xl overflow-x-auto shadow-lg rounded-lg bg-white">
        <table className="table-auto w-full border-collapse">
          <thead>
            <tr className="bg-[#1F5059] text-white text-sm sm:text-base">
              <th className="px-4 py-2">{t("rank")}</th>
              <th className="px-4 py-2">{t("player")}</th>
              <th className="px-4 py-2">{t("age")}</th>
              <th className="px-4 py-2">{t("team")}</th>
              <th className="px-4 py-2">{t("appearances")}</th>
              <th className="px-4 py-2">{t("redcards")}</th>
              <th className="px-4 py-2">{t("yellowcards")}</th>
              <th className="px-4 py-2">{t("goals")}</th>
              <th className="px-4 py-2">{t("assists")}</th>
              <th className="px-4 py-2">{t("minutes")}</th>
              <th className="px-4 py-2">{t("position")}</th>
              <th className="px-4 py-2">{t("rating")}</th>
              <th className="px-4 py-2">{t("penalty")}</th>
            </tr>
          </thead>
          <tbody>
            {topRedCards.map((item, index) => (
              <tr
                key={item.player.id}
                className={`${
                  index % 2 === 0 ? "bg-gray-50" : "bg-white"
                } text-center text-xs sm:text-sm md:text-base`}
              >
                <td className="px-4 py-2">{index + 1}</td>
                <td className="px-4 py-2 flex items-center justify-start">
                  <img
                    src={item.player.photo}
                    alt="Player"
                    className="w-8 h-8 mr-2 rounded-full"
                  />
                  {item.player.name}
                </td>
                <td className="px-4 py-2">{item.player.age}</td>
                <td className="px-4 py-2 flex items-center justify-start">
                  <img
                    src={item.team.logo}
                    alt="Team"
                    className="w-6 h-6 mr-2"
                  />
                  {item.team.name}
                </td>
                <td className="px-4 py-2">{item.statistics.games.appearances}</td>
                <td className="px-4 py-2">{item.statistics.cards.red}</td>
                <td className="px-4 py-2">{item.statistics.cards.yellow}</td>
                <td className="px-4 py-2">{item.statistics.goals.total}</td>
                <td className="px-4 py-2">{item.statistics.goals.assists}</td>
                <td className="px-4 py-2">{item.statistics.games.minutes}</td>
                <td className="px-4 py-2">{item.statistics.games.position}</td>
                <td className="px-4 py-2">
                  {parseFloat(item.statistics.games.rating).toFixed(2)}
                </td>
                <td className="px-4 py-2">
                  {item.statistics.penalty.scored}/{
                    item.statistics.penalty.scored + item.statistics.penalty.missed
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Loading Indicator */}
      {isLoading && (
        <div className="flex justify-center items-center mt-6">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-[#1F5059]"></div>
        </div>
      )}
    </div>
  );
};

export default TopRedCards;
