import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getNewsFromServer } from "../API/ServerApi";

// Utility functions
const truncateDescription = (description: string, maxChars: number = 80): string => {
  return description.length <= maxChars ? description : `${description.slice(0, maxChars)}...`;
};

const truncatetitle = (title: string, maxWords: number = 4): string => {
  const words = title.split(" ");
  return words.length <= maxWords ? title : `${words.slice(0, maxWords).join(" ")}...`;
};

const NewsList: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [allNews, setAllNews] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visibleItems, setVisibleItems] = useState(2); // Initial visible items

  // Fetch news once
  useEffect(() => {
    const fetchNews = async () => {
      setIsLoading(true);
      const res = await getNewsFromServer();
      setIsLoading(false);

      const newsArray = Object.values(res.news);
      setAllNews(newsArray);
    };

    fetchNews();
  }, []);

  // Select news based on the current language
  const language = i18n.language === "English" ? "en" : "ar";
  const filteredNews = allNews
    .map((item: any) => item[language])
    .filter((item: any) => item?.newsFirstImage && item?.newsTeaser !== "[Removed]");

  const mostRecentNews = filteredNews[0] || null;
  const restOfNews = filteredNews.slice(1);

  // Handle "View More" navigation
  const handleViewMore = () => {
    navigate("/news");
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-gray-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4 flex flex-col md:flex-row gap-4 justify-center items-center w-full">
      {/* Most Recent News */}
      {mostRecentNews && (
        <div className="bg-white shadow-lg rounded-lg w-full sm:w-1/2 h-full max-w-[400px] md:max-w-none">
          <div className="flex flex-col justify-center items-center">
            <img
              src={mostRecentNews.newsFirstImage}
              alt={mostRecentNews.newsTeaser}
              className="w-full h-48 object-contain rounded-md"
            />
            <div className="w-full mt-4 p-6 bg-[#056A4C] bg-opacity-90 text-white">
              <h1 className="text-lg font-bold">{truncatetitle(mostRecentNews.newsTeaser)}</h1>
              <p className="text-sm">{truncateDescription(mostRecentNews.newsHeadline)}</p>
            </div>
          </div>
        </div>
      )}

      {/* Rest of the News */}
      <div className="w-full sm:w-1/2 flex flex-col gap-y-4">
        {restOfNews.slice(0, visibleItems).map((newsItem, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg flex flex-col md:flex-row p-4">
            <img
              src={newsItem.newsFirstImage}
              alt={newsItem.newsTeaser}
              className="w-full md:w-1/3 h-32 object-cover rounded-md"
            />
            <div className="flex flex-col md:pl-4">
              <h2 className="text-sm font-bold mb-2">{truncatetitle(newsItem.newsTeaser)}</h2>
              <p className="text-xs text-gray-600">{truncateDescription(newsItem.newsHeadline)}</p>
            </div>
          </div>
        ))}
        <button
          onClick={handleViewMore}
          className="self-center mt-4 px-6 py-2 bg-[#4c9a82] text-white rounded-lg"
        >
          {t("showMore")}
        </button>
      </div>
    </div>

  );
};

export default NewsList;
