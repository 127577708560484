import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getStandingsFromServer } from "../API/ServerApi";

interface Team {
  id: number;
  name: string;
  logo: string;
}

interface Standing {
  rank: number;
  team: Team;
  points: number;
  goalsDiff: number;
  all: {
    played: number;
    win: number;
    draw: number;
    lose: number;
    goals: {
      for: number;
      against: number;
    };
  };
}

const StandingsTable = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "Arabic";

  const [visibleRows, setVisibleRows] = useState(5);
  const [standings, setStandings] = useState<Standing[]>([]);

  const showMore = () => {
    navigate("/table");
  };

  useEffect(() => {
    const getStands = async () => {
      const res = await getStandingsFromServer();
      const standingsGot = res.standings;
      console.log("res stands", standingsGot);
      setStandings(standingsGot);
    };
    getStands();
  }, []);

  return (
    <div className="w-auto text-sm container py-5 mx-1 flex flex-col w-full sm:w-11/12 max-w-screen-lg" dir={isArabic ? "rtl" : "ltr"}>
      <div className="overflow-x-auto" dir={isArabic ? "rtl" : "ltr"}>
        <table
          className="table-auto border-collapse text-left w-full"
          dir={isArabic ? "rtl" : "ltr"}
        >
          <thead>
            <tr className="bg-white text-sm text-[#1F5059]">
              <th className="px-2 py-2">{t("rank")}</th>
              <th className="px-4 py-2">{t("team")}</th>
              <th className="px-4 py-2">{t("points")}</th>
              <th className="px-4 py-2">{t("gamesPlayed")}</th>
              <th className="px-4 py-2">{t("wins")}</th>
              <th className="px-4 py-2">{t("draws")}</th>
              <th className="px-4 py-2">{t("losses")}</th>
              <th className="px-4 py-2">{t("goalsFor")}</th>
              <th className="px-4 py-2">{t("goalsAgainst")}</th>
              <th className="px-4 py-2">{t("goalDifference")}</th>
            </tr>
          </thead>
          <tbody>
            {standings.slice(0, visibleRows).map((standing, index) => (
              <tr
                key={standing.rank}
                className={`border-b text-sm ${
                  index % 2 === 0 ? "bg-white" : "bg-transparent"
                }`}
              >
                <td className="px-2 py-2">{standing.rank}</td>
                <td className="text-sm pr-5 pl-1 py-2 flex items-center whitespace-nowrap font-semibold">
                  <img
                    src={standing.team.logo}
                    alt={`${standing.team.name} logo`}
                    className="w-5 h-5 mr-2"
                  />
                  {standing.team.name}
                </td>
                <td className="px-2 py-2">{standing.points}</td>
                <td className="px-2 py-2">{standing.all.played}</td>
                <td className="px-2 py-2">{standing.all.win}</td>
                <td className="px-2 py-2">{standing.all.draw}</td>
                <td className="px-2 py-2">{standing.all.lose}</td>
                <td className="px-2 py-2">{standing.all.goals.for}</td>
                <td className="px-2 py-2">{standing.all.goals.against}</td>
                <td className="px-2 py-2">{standing.goalsDiff}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {visibleRows < standings.length && (
        <div className="flex justify-center items-center">
          <button
            onClick={showMore}
            className="mt-4 px-6 py-3 bg-[#4c9a82] text-white text-sm rounded-lg hover:bg-[#3b7c65] transition-all"
          >
            {t("showMore")}
          </button>
        </div>
      )}
    </div>
  );
};

export default StandingsTable;
