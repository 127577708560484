import PoolsPrediction from '../components/Predictions/Pools'
import PredictionMainComp from '../components/Predictions/PredictionMainComp'
import ThisWeekExtra from '../components/Predictions/ThisWeekExtra'

function PredictionScreen() {

  return (
    <section className="flex justify-center items-center p-4">
      <div className="flex flex-col items-center gap-y-6 py-4 px-4 w-screen text-xl">
        <PoolsPrediction />
        <ThisWeekExtra />
        <PredictionMainComp />
      </div>
    </section>

  )
}

export default PredictionScreen  