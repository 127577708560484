import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { startOfWeek, endOfWeek, format } from "date-fns";
import PredictionPopup from "../predictionPopup";
import SuccessModal from "../success-failure-models/SuccessModal";
import FailureModal from "../success-failure-models/FailureModal";
import { getMyPredictions } from "../../API/ServerApi";

const MyPredictionsMain = () => {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
    const [showFailureModal, setShowFailureModal] = useState<boolean>(false);
    const [failureMessage, setFailureMessage] = useState<string>("");
    const [allPredictions,setAllPredictions]=useState<any[]>([])
    const [myPredictions, setMyPredictions] = useState<any[]>([]);
    const [groupedGames, setGroupedGames] = useState<any[]>([]);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    interface TeamNameProps {
        name: string;
    }
    const isArabic = i18n.language === "Arabic";
    const language = isArabic ? 'ar' : "en"
    const TeamName: React.FC<TeamNameProps> = ({ name }) => {
        if (name?.length > 15) {
            const [firstPart, secondPart, ...restParts] = name.split(" ");

            if (restParts?.length > 0) {
                return (
                    <>
                        {firstPart} {secondPart} <br /> {restParts.join(" ")}
                    </>
                );
            } else {
                return (
                    <>
                        {firstPart} <br /> {secondPart || ""}
                    </>
                );
            }
        }
        return <>{name}</>;
    };


    const Game = ({ game }: any) => {
        return (
            <div className="grid grid-cols-1 items-center gap-4">
                <div className="flex items-center justify-start">
                    <img
                        src={game.home_team?.logo}
                        alt={`${game.home_team?.name} logo`}
                        className="w-7 h-7"
                    />
                    <span className="text-sm min-w-[110px]">
                        <TeamName name={game.home_team?.name} />
                    </span>
                    <span className="min-w-[10px]">-</span>
                    <span className="text-sm min-w-[100px]">
                        <TeamName name={game.away_team?.name} />
                    </span>
                    <img
                        src={game.away_team?.logo}
                        alt={`${game.away_team?.name} logo`}
                        className="w-7 h-7"
                    />
                    <span className="text-base sm:text-lg text-gray-700 font-semibold bg-green-200 p-1 ml-5 sm:ml-8 flex flex-nowrap">
                        {game.goals?.home != null && game.goals?.away != null
                            ? `${game.goals.home}-${game.goals.away}`
                            : "-"}

                    </span>
                    {/*   //t("NotPlayed")*/}
                    <span
                        className={`w-8 h-8 text-lg flex items-center justify-center rounded-full border font-bold ml-8 ${game.goals?.home > game.goals?.away
                            ? "bg-orange-500 text-white"
                            : game.goals?.home < game.goals?.away
                                ? "bg-red-500 text-white"
                                : "bg-gray-300 text-black"
                            }`}
                    >
                        {game.goals?.home !== null && game.goals?.away !== null
                            ? game.goals?.home > game.goals?.away
                                ? "1"
                                : game.goals?.home < game.goals?.away
                                    ? "2"
                                    : "X"
                            : ""}
                    </span>

                </div>
                {/* <div className=" flex items-center justify-center "> 
                <span className="text-sm min-w-[110px]">
                date heree
                    </span>
                    </div> */}
            </div>
        );
    };

    const MyPredictionColumn = ({ game }: any) => {
        return (
            <div className="flex items-center justify-between gap-4 pr-10">
                  
                    {game.choice ? (
                        <>
                            <span
                                className={`w-8 h-8 text-lg flex items-center justify-center rounded-full border font-bold ${game.choice === "1"
                                    ? "bg-orange-700 text-white"
                                    : game.choice === "x"
                                        ? "bg-white text-black border-gray-300"
                                        : game.choice === "2"
                                            ? "bg-red-200 text-black"
                                            : "bg-gray-200 text-black"
                                    }`}
                            >
                                {t(game.choice.toUpperCase())}
                            </span>

                            {
                                game.goals?.home != null && game.goals?.away != null ?

                                <>

                                 {/* Success or Failure Tick */}
                            {((game.choice === "1" && game.goals.home > game.goals.away) ||
                                (game.choice === "2" && game.goals.home < game.goals.away) ||
                                (game.choice === "x" && game.goals.home === game.goals.away)) && (
                                    <span className="w-8 h-8 text-lg flex items-center justify-center rounded-full bg-green-700 text-white">
                                        ✓
                                    </span>
                                )}
                            {((game.choice === "1" && game.goals.home <= game.goals.away) ||
                                (game.choice === "2" && game.goals.home >= game.goals.away) ||
                                (game.choice === "x" && game.goals.home !== game.goals.away)) && (
                                    <span className="w-8 h-8 text-lg flex items-center justify-center rounded-full bg-red-700 text-white">
                                        ✗
                                    </span>
                                )}
                                </>

                                :
                                <span className="w-8 h-8 text-lg flex items-center justify-center rounded-full bg-gray-300 text-white">
                        
                                </span> 
                            }
                           
                        </>
                    ) : game.predicted_score ? (
                        <div className="flex items-center justify-between w-full gap-2">
                            <span
                                className={`w-9 h-9 text-center text-base flex items-center justify-center rounded-full border font-bold bg-blue-200 text-black`}
                            >
                                {game.predicted_score.home}-{game.predicted_score.away}
                            </span>

                        {game.goals?.home != null && game.goals?.away != null ?    <span
                                className={`w-8 h-8 text-lg flex items-center justify-center rounded-full border font-bold ${(game.predicted_score.home === game.goals?.home &&
                                    game.predicted_score.away === game.goals?.away)
                                    ? "bg-green-700 text-white"
                                    : "bg-red-700 text-white"
                                    }`}
                            >
                                {game.predicted_score.home === game.goals?.home &&
                                    game.predicted_score.away === game.goals?.away
                                    ? "✓"
                                    : "✗"}
                            </span>
                            :
                            <span className="w-8 h-8 text-lg flex items-center justify-center rounded-full bg-gray-300 text-white">
                        
                            </span>    
                        }
                        </div>
                    ) : (
                        <span className="text-sm text-gray-500">{t("No Prediction")}</span>
                    )}
               
            </div>
        );
    };

    const getMyPreds = async () => {
        setIsLoading(true);
        try {
            const response = await getMyPredictions();
            console.log(response);
            if (!response || !response.success) {
                setIsError(true);
                return;
            }
            setGroupedGames(response.predictions)
            // setMyPredictions(response.predictions);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    

    useEffect(() => {
        getMyPreds();
    }, []);



    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-b-4 border-gray-500"></div>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="flex justify-center items-center h-screen">
                <span>{t("somethingWrong")}</span>
            </div>
        );
    }

    if (!Object.entries(groupedGames)?.length) {
        return (
            <div className="flex flex-col justify-center items-center h-screen space-y-4">
                <span>{t("NoPredictionsYet")}</span>
                <button
                    className="p-3 rounded-lg text-xl bg-[#056A4C] text-white mt-4"
                    onClick={() => setIsPopupOpen(true)}
                >
                    {t("predictionPage")}
                </button>
                {isPopupOpen && (
                    <PredictionPopup handleClosePopup={() => setIsPopupOpen(false)} />
                )}
            </div>
        );
    }

   
    function formatDateRangeToArabic(dateRange: string) {
        const monthsMap = {
            "January": "يناير",
            "February": "فبراير",
            "March": "مارس",
            "April": "أبريل",
            "May": "مايو",
            "June": "يونيو",
            "July": "يوليو",
            "August": "أغسطس",
            "September": "سبتمبر",
            "October": "أكتوبر",
            "November": "نوفمبر",
            "December": "ديسمبر"
        } as any;

        // Split the date range
        const [startDate, endDate] = dateRange.split(" - ");
        const [startMonth, startDay] = startDate.split(" ");
        const [endMonth, endDay] = endDate.split(" ");

        // Translate and format
        const startArabic = `${startDay} ${monthsMap[startMonth]}`;
        const endArabic = `${endDay} ${monthsMap[endMonth]}`;
        return `${startArabic} - ${endArabic}`;
    }

    console.log("grp games by week", groupedGames);
    return (
        <div className="w-screen py-6 bg-gray-100">
            {/* Wrapper for horizontal scroll on smaller screens */}
            <div className=" w-full overflow-x-scroll">
                {/* Main container */}
                <div className="flex flex-col ">

                {/* <button
        //   className="p-3 rounded-lg text-xl bg-[#056A4C] text-white w-[80%]  mh-20 pointer-events-auto relative"
        className="p-3 rounded-lg text-xl bg-[#056A4C] text-white w-4/5 mh-20 pointer-events-auto relative mx-auto"
          onClick={() => {
            setIsPopupOpen(true);
            console.log("popup");
          }}
        >
          {t('predictionPage')}
        </button> */}

                    {/* Header Section */}
                    {Object.entries(groupedGames).map(([weekRange, { round, bets }]) => (
                        <div key={weekRange} className="mb-10 px-0 lg:px-8 min-w-[700px]">
                            {/* Section Title */}
                            <h2
                                className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 text-gray-700 text-center "
                                dir={isArabic ? "rtl" : "ltr"}
                            >
                                {isArabic
                                    ? `${formatDateRangeToArabic(weekRange)} (${t('Round')} ${round})`
                                    : `(${t('Round')} ${round}) ${weekRange}`}
                            </h2>

                            {/* Table Header */}
                            <div className="grid grid-cols-5 gap-2 sm:gap-4 text-center pb-2 sm:pb-4 mb-4 sm:mb-6 font-semibold text-gray-600 text-sm sm:text-base ">
                                <div className="col-span-2 min-w-[300px]">{t("MatchHistory")}</div>
                                <div className="min-w-[300px] ">{t("MyPrediction")}</div>
                                <div className="min-w-[300px] sm:min-w-[200px] pr-[50px]">{t("MyResults")}</div>
                                <div className="text-center md:text-justify">{t("Pools")}</div>
                            </div>

                            {/* Bets Section */}
                            {Object.entries(bets as any[]).map(([betId, { predInfo, matches }]) => (
                                <div
                                    key={predInfo?.bet_id}
                                    className="grid grid-cols-[3fr,1fr] gap-4 sm:gap-6 mb-6 sm:mb-8 bg-white rounded-lg shadow p-4 sm:p-6"
                                >
                                    {/* Matches Section */}
                                    <div>
                                        <h3
                                            className="text-sm sm:text-lg font-semibold mb-2 sm:mb-4 text-gray-800 text-start"
                                            dir={isArabic ? "rtl" : "ltr"}
                                        >
                                            {`${t('Createdat')}: ${new Date(betId).toLocaleString(language, { timeZone: 'Asia/Riyadh' })}`}
                                        </h3>
                                        {(matches as any[]).map((game, index) => (
                                            <div
                                                key={index}
                                                className={`grid grid-cols-[2fr,1fr] gap-2 sm:gap-4 items-center text-center ${index === 6 ? 'border-b-4 border-gray-300' : 'border-b'
                                                    } py-2 sm:py-4 min-w-[600px]`}
                                            >
                                                <Game game={game} />
                                                <MyPredictionColumn game={game} />
                                            </div>
                                        ))}
                                    </div>

                                    {/* Results Section */}
                                    <div className="flex justify-center items-center">
                                        <div className="grid gap-2 sm:gap-4">
                                            {['pool1', 'pool2', 'pool3'].map((pool, idx) => (
                                                <div
                                                    key={idx}
                                                    className="bg-gray-50 border border-gray-300 rounded-lg shadow p-2 sm:p-4 text-center"
                                                >
                                                    <p className="font-bold text-sm sm:text-lg text-gray-700">{t(pool)}</p>
                                                    <p className="text-xs sm:text-sm text-gray-500">0 {t('tokens')}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            {/* Modals */}
            <SuccessModal
                isOpen={showSuccessModal}
                onClose={() => setShowSuccessModal(false)}
            />
            <FailureModal
                isOpen={showFailureModal}
                onClose={() => setShowFailureModal(false)}
                failureMessage={failureMessage}
            />
            {isPopupOpen && (
                <PredictionPopup handleClosePopup={() => setIsPopupOpen(false)} />
            )}
        </div>

    );

};

export default MyPredictionsMain;
